import React from "react";
import Link from "gatsby-link";

const Footer = () => (
  <footer className="bg-gray-900 text-gray-300 text-sm">
    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 col-gap-10 row-gap-10 py-16 px-10 lg:px-0">
      <div className="flex flex-wrap sm:space-x-4 items-center md:col-span-2 row-gap-5 md:justify-center">
        {/* <img src={logoWhite} alt="cm teleservices logo" width={250} /> */}
        <div>
          <svg
            className="h-32 fill-current text-white pr-2"
            viewBox="0 0 11.78 10.33"
          >
            <path d="M0 .31V10a.31.31 0 00.31.31h11.16a.31.31 0 00.31-.31V.31a.31.31 0 00-.31-.31H.31A.31.31 0 000 .31zm10.28 4.3h-.15a.61.61 0 00-.32.08.34.34 0 00-.14.15 1.6 1.6 0 000 .43v3a1.58 1.58 0 000 .44.41.41 0 00.16.16.61.61 0 00.3.06h.15v.13H7.89V9H8a.61.61 0 00.32-.07.27.27 0 00.13-.16 1.22 1.22 0 000-.43V4.89L6.78 9.05a3.84 3.84 0 01-1.25.95 3.73 3.73 0 01-1.53.25 4.35 4.35 0 01-2-.46A3.18 3.18 0 01.62 8.48a3.64 3.64 0 01-.49-1.83 3.77 3.77 0 01.54-1.94 3.85 3.85 0 011.44-1.42A3.89 3.89 0 014 2.79a4.55 4.55 0 011.59.32 2.34 2.34 0 00.61.19.42.42 0 00.3-.12.71.71 0 00.16-.39h.2v2.47H6.7a2.63 2.63 0 00-.86-1.48 2.2 2.2 0 00-1.44-.52 2.15 2.15 0 00-1.22.38 2.31 2.31 0 00-.82 1A4.58 4.58 0 002 6.42a5.9 5.9 0 00.24 1.74A2.32 2.32 0 003 9.35a2.1 2.1 0 001.34.41 2.54 2.54 0 001.23-.3 3.73 3.73 0 00.93-.75L4.9 4.92v3.24a2.78 2.78 0 000 .41.49.49 0 00.19.28.81.81 0 00.46.1v.13H4V9a.59.59 0 00.29 0 .4.4 0 00.19-.14.6.6 0 00.11-.24 2.3 2.3 0 000-.35v-3a1.64 1.64 0 000-.44.39.39 0 00-.16-.15.6.6 0 00-.3-.07H4v-.12h1.88l1.29 3 1.24-3h1.87zM8.19 3.48a1.19 1.19 0 011.6-.14l-.28.35a.73.73 0 00-1 .09zm1.92-.34A1.52 1.52 0 008 3.19l-.32-.31a2 2 0 011.49-.61 2 2 0 011.29.54zm.6-.56a2.32 2.32 0 00-1.56-.68 2.38 2.38 0 00-1.75.69l-.31-.32a2.8 2.8 0 012.07-.82 2.76 2.76 0 011.84.81zm.61-.58a3.18 3.18 0 00-4.49 0l-.31-.32a3.69 3.69 0 012.67-1 3.56 3.56 0 012.44 1.07z" />
          </svg>
        </div>
        <p className="max-w-xs">
          We help organization work smarter and grow faster. Reach out to us to
          build effective networks, reduce costs, manage risk and regulation.
          Save time, save money, grow and succeed.
        </p>
      </div>

      <div className="col-span-1">
        <p>
          <strong>Get in Touch</strong>
        </p>
        <address className="mt-2">
          <p className="leading-relaxed">
            <a href="mailto:info@cmteleservices.com.np">
              info@cmteleservices.com.np
            </a>
          </p>
          <p>
            You may also want to visit us:
            <br />
            Kupondole, Lalitpur
            <br />
            Nepal
          </p>
        </address>
      </div>
      <div className="col-span-1">
        <p>
          <strong>Quick Links</strong>
        </p>
        <p className="mt-2">
          <Link to="/">Home</Link> |<Link to="/about"> Career</Link> |
          <Link to="/services"> Services</Link>
        </p>
        <div className="pt-5">
          <a
            href="http://www.facebook.com/mannasbabu"
            target="_blank"
            rel="noopener noreferrer"
            className="flex space-x-2 items-center"
          >
            <svg className="h-8 w-8" viewBox="0 0 72 72">
              <rect fill="#4460A0" width="72" height="72" rx="8" />
              <path
                d="M60.464 13.417v9.31l-5.526.015c-4.331 0-5.166 2.058-5.166 5.066v6.662H60.09L58.752 44.89h-8.98V72H39.01V44.889H30V34.47h9.01v-7.684C39.01 17.864 44.448 13 52.42 13c3.8 0 7.08.288 8.044.417z"
                fill="#FFF"
              />
            </svg>
            <p>Connect on Facebook</p>
          </a>
        </div>
      </div>
    </div>
    <div className="border-t border-gray-800 py-5">
      <div className="max-w-6xl mx-auto text-sm px-6 lg:px-0">
        <p>&copy; All Rights Reserved CM Teleservices Pvt. Ltd.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
