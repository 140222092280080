import React from "react"
import { useState } from "react"

// import Logo from "../images/logo.svg"
import Link from "gatsby-link"

const navItems = [
  { href: "/", title: "Home" },
  { href: "/about", title: "About" },
  { href: "/services", title: "Services" },
  { href: "/careers", title: "Careers" },
  { href: "/contact", title: "Contact" },
]

function Navbar() {
  const [open, setOpen] = useState(false)

  return (
    <div className="sticky top-0 w-full z-10 topnav">
      <nav>
        <div className="flex items-center justify-between py-4 border-b px-8 md:px-16 bg-white">
          <div>
            <Link to="/">
              {/* <img src={Logo} alt="cm teleservices logo" className="h-10" /> */}
              <div className="flex space-x-2 items-end">
                <div>
                  <svg
                    className="h-12 fill-current text-blue-800"
                    viewBox="0 0 11.78 10.33"
                  >
                    <path d="M0 .31V10a.31.31 0 00.31.31h11.16a.31.31 0 00.31-.31V.31a.31.31 0 00-.31-.31H.31A.31.31 0 000 .31zm10.28 4.3h-.15a.61.61 0 00-.32.08.34.34 0 00-.14.15 1.6 1.6 0 000 .43v3a1.58 1.58 0 000 .44.41.41 0 00.16.16.61.61 0 00.3.06h.15v.13H7.89V9H8a.61.61 0 00.32-.07.27.27 0 00.13-.16 1.22 1.22 0 000-.43V4.89L6.78 9.05a3.84 3.84 0 01-1.25.95 3.73 3.73 0 01-1.53.25 4.35 4.35 0 01-2-.46A3.18 3.18 0 01.62 8.48a3.64 3.64 0 01-.49-1.83 3.77 3.77 0 01.54-1.94 3.85 3.85 0 011.44-1.42A3.89 3.89 0 014 2.79a4.55 4.55 0 011.59.32 2.34 2.34 0 00.61.19.42.42 0 00.3-.12.71.71 0 00.16-.39h.2v2.47H6.7a2.63 2.63 0 00-.86-1.48 2.2 2.2 0 00-1.44-.52 2.15 2.15 0 00-1.22.38 2.31 2.31 0 00-.82 1A4.58 4.58 0 002 6.42a5.9 5.9 0 00.24 1.74A2.32 2.32 0 003 9.35a2.1 2.1 0 001.34.41 2.54 2.54 0 001.23-.3 3.73 3.73 0 00.93-.75L4.9 4.92v3.24a2.78 2.78 0 000 .41.49.49 0 00.19.28.81.81 0 00.46.1v.13H4V9a.59.59 0 00.29 0 .4.4 0 00.19-.14.6.6 0 00.11-.24 2.3 2.3 0 000-.35v-3a1.64 1.64 0 000-.44.39.39 0 00-.16-.15.6.6 0 00-.3-.07H4v-.12h1.88l1.29 3 1.24-3h1.87zM8.19 3.48a1.19 1.19 0 011.6-.14l-.28.35a.73.73 0 00-1 .09zm1.92-.34A1.52 1.52 0 008 3.19l-.32-.31a2 2 0 011.49-.61 2 2 0 011.29.54zm.6-.56a2.32 2.32 0 00-1.56-.68 2.38 2.38 0 00-1.75.69l-.31-.32a2.8 2.8 0 012.07-.82 2.76 2.76 0 011.84.81zm.61-.58a3.18 3.18 0 00-4.49 0l-.31-.32a3.69 3.69 0 012.67-1 3.56 3.56 0 012.44 1.07z" />
                  </svg>
                </div>
                <div className="leading-tight text-blue-800">
                  <h3 className="font-bold uppercase">C.M.Teleservices</h3>
                  <p className="text-sm font-medium">Connecting Nepal</p>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <ul className="hidden  md:flex items-center justify-end space-x-4 text-blue-800 font-semibold tracking-wide">
              {navItems.map(item => (
                <li key={`${item.title}`}>
                  <Link
                    to={item.href}
                    className="py-6 px-4"
                    activeStyle={{ color: "blue" }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            {/* mobile navigation */}
            <div className="-mr-2 flex md:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none"
                onClick={() => setOpen(!open)}
              >
                <svg
                  className={`block h-6 w-6 text-blue-800 hover:text-white ${
                    open ? "hidden" : ""
                  }`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 text-blue-800 hover:text-white ${
                    open ? "" : "hidden"
                  }`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={`bg-white md:hidden ${open ? "" : "hidden"}`}>
          <div className="px-10 pt-2 pb-3 sm:px-3 space-y-2 font-medium text-lg">
            {navItems.map(item => (
              <div key={item.title}>
                <Link to={item.href}>
                  <div className="border-b-2 border-transparent hover:border-blue-600 pb-1">
                    {item.title}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
